import heroImg from "../../assets/images/hero.webp";
import worldImg from "../../assets/images/world.webp";
import fiberImg from "../../assets/images/fiber.webp";
import palaceImg from "../../assets/images/palace.webp";
import bitImg from "../../assets/images/bit.webp";
import paperSvg from "../../assets/icons/paper.svg";
import successSvg from "../../assets/icons/success.svg";
import logoImg from "../../assets/LOGO/Color/FUTURE_W3B_Logo_Blu3.png";
import logoBlackImg from "../../assets/LOGO/Black/FUTURE_W3B_logo-03.png";
import paper from "../../assets/files/paper.pdf";

import { FormEvent, useState } from "react";
import { downloadFile } from "../../utils/generic";
import axios from "axios";

interface Props {
  onClose: Function;
}

function DownloadModal({ onClose }: Props) {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const email = (e.target as any).email.value;

      await axios.post("https://fw3.polygonsteps.xyz/", { email });

      downloadFile(paper, "Future W3b - Paper.pdf");
      setSuccess(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div onClick={() => onClose()} className="download-modal-container">
      <div
        onClick={(e) => e.stopPropagation()}
        className="download-modal-content d-flex flex-column align-items-center justify-content-center"
      >
        {success ? (
          <>
            <img className="paper" src={successSvg} alt="" />
            <p className="display-2">Fatto!</p>
            <p className="fs-5 text-center">Controlla tra i tuoi download</p>
            <button onClick={() => onClose()}>CHIUDI</button>
          </>
        ) : (
          <>
            <img className="paper" src={paperSvg} alt="" />
            <p className="display-2">Scarica il paper</p>
            <p className="fs-5 text-center">
              Lascia la tua email per scaricare il paper
            </p>
            <br />
            <form onSubmit={submit} className="d-flex flex-column gap-2">
              <input
                disabled={loading}
                placeholder="mario.rossi@gmail.com"
                type="email"
                name="email"
                required
              />
              <button disabled={loading} type="submit">
                SCARICA
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default function Home() {
  const [open, setOpen] = useState(false);

  return (
    <main id="home">
      {open ? <DownloadModal onClose={() => setOpen(false)} /> : null}

      {/* header */}
      <header>
        <div className="btn-container">
          <a className="color-primary" onClick={() => setOpen(true)}>
            SCARICA IL PAPER
            <br />
            BLOCKCHAIN & NEW MARKETS
          </a>
        </div>
      </header>

      {/* hero */}
      <div className="hero">
        <img className="hero-img" src={heroImg} alt="" />
        <img className="hero-logo" src={logoImg} alt="" />

        <hgroup>
          <h1>Think Tank</h1>
          <h2>Blockchain | Ai | Realtà Estese</h2>
        </hgroup>
      </div>

      {/* curious */}
      <div className="container mt-5 mb-5">
        <div className="content">
          <div className="row">
            <div className="col-12 col-md-6 p-3">
              <div className="d-flex flex-column justify-content-center h100">
                <p className="display-2">
                  <span className="color-primary">Curiosi</span> di natura
                </p>
                <p className="fs-5">
                  Approfondiamo gli aspetti culturali, sociali, economici e
                  politici generati dalle principali innovazioni digitali.
                  Ascoltiamo e dialoghiamo con le comunità affinché
                  l'innovazione digitale possa essere compresa e fruita con
                  equilibrio e equità. Pensiamo che l'evoluzione informatica,
                  che procede verso l'intelligenza artificiale, le realtà estese
                  e gli ambienti virtuali e le nuove tecnologie a reti come
                  blockchain, produce modifiche nelle relazioni fra gli esseri
                  umani determinando nuove esperienze e forme di vita,
                  individuale, sociale e in relazione con l'ambiente. Vogliamo
                  capirne di più.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="d-flex justify-content-center align-items-center h100">
                <img src={bitImg} alt="" className="world" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* connect */}
      <div className="container mt-5 mb-5">
        <div className="content">
          <div className="connect">
            <div className="connect-image-container">
              <div className="image-mask"></div>
              <div className="image-mask"></div>
              <div className="image-mask"></div>
              <img className="connect-image" src={fiberImg} alt="" />

              <div className="text">
                <p className="display-2">Connettori per passione</p>
                <p className="fs-5">
                  Raccogliamo i papers più interessanti prodotti dai centri di
                  ricerca universitari e dagli osservatori specializzati e
                  coinvolgiamo le migliori competenze accademiche, professionali
                  e imprenditoriali, in forma singola e associata, per
                  promuoverne l'incontro, il dialogo e il confronto. Dalla
                  contaminazione del sapere e del saper fare, dall'intelligenza
                  collettiva, pensiamo possano nascere politiche in grado di
                  contribuire al nostro benessere. Protagonisti del cambiamento.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* change */}
      <div className="container mt-5 mb-5">
        <div className="content">
          <div className="row">
            <div className="col-12 col-md-6 p-3">
              <div className="d-flex justify-content-center align-items-center h100">
                <img src={palaceImg} alt="" className="world" />
              </div>
            </div>
            <div className="col-12 col-md-6 p-3">
              <div className="d-flex flex-column justify-content-center h100">
                <p className="display-2">
                  Protagonisti del{" "}
                  <span className="color-primary">cambiamento</span>
                </p>
                <p className="fs-5">
                  Disponiamo di tecnologie che modificano la nostra realtà e le
                  nostre esperienze molto più velocemente di quanto siamo in
                  grado di assorbire. Asset digitali, contratti smart e token,
                  metaversi, organizzazioni autonome decentralizzate (DAO),
                  chatbot basate sull'IA, stanno producendo rilevanti effetti
                  sulle nostre vite. Intendiamo agire sulla possibilità di
                  allineamento fra innovazione e prassi sostenibili proponendo
                  interventi legislativi finalizzati a migliorare, in
                  reciprocità, i modelli scolastici e formativi, il lavoro, le
                  istituzioni e la politica, il tempo libero, il godimento
                  dell'arte, della natura, della bellezza.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* web3 */}
      <div className="web3">
        <div className="container mt-5 mb-5">
          <div className="content">
            <div className="row">
              <div className="col-12 col-md-6 p-3">
                <div className="d-flex flex-column justify-content-center h100">
                  <p className="display-2">Il think tank del web3</p>
                  <p className="fs-5">
                    Intendiamo analizzare le dinamiche che determinano il
                    progresso tecnologico e vogliamo approfondire gli effetti e
                    le implicazioni dei cambiamenti in corso per individuare e
                    suggerire comportamenti che possano permettere agli
                    individui, alle istituzioni e alle imprese di agire in
                    equilibrio e nel reciproco rispetto. Favoriamo progetti e
                    interventi di policy utili allo sviluppo dell'innovazione
                    digitale, anche come bene comune, attraverso il dialogo fra
                    accademia, imprese, istituzioni e individui. Promuoviamo la
                    contaminazione all'interno delle comunità web3 consapevoli
                    che le differenti competenze, esperienze e visioni siano
                    essenziali per la creazione di ecosistemi efficienti e
                    sostenibili.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 p-3">
                <div className="d-flex justify-content-center align-items-center h100">
                  <img src={worldImg} alt="" className="world" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      {/* footer */}
      <footer className="mt-5 pt-5 pb-5">
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-12 col-md-6">
                <img src={logoBlackImg} alt="" className="footer-logo" />
              </div>
              <div className="col-12 col-md-6"></div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="small">
                  <small>
                    © 2023 Future W3b. -{" "}
                    <a href="mailto:stefano.ceci@futurew3b.it">
                      stefano.ceci@futurew3b.it
                    </a>
                    <br /> All RIGHT RESERVED
                  </small>
                </p>
                <p className="small">
                  <small>
                    Images on this website are created by BlueWillow AI e minted
                    on OpenSea -{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://opensea.io/collection/futurew3b-collection-thinktank"
                    >
                      Collection
                    </a>
                  </small>
                </p>
              </div>
              <div className="col-12 col-md-6"></div>
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
}
